var spinner = spinner || {};

(function ($) {
  Drupal.behaviors.discover_loading_spinner_v1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $thismodule = $('.js-discover-loading-spinner', context);

      // Append the spinner.
      $thismodule.append(spinner.el);
    }
  };
})(jQuery);
